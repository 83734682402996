<template>
  <div class="form-basket">
    <form class="form-basket__contacts">
      <div class="form-basket__label">{{ $t('Контактні дані') }}</div>
      <div class="form-basket__fields">
        <BaseInput :value="inputs.user_name.value"
                   :error="inputs.user_name.error"
                   :label="$t('Ім\'я')"
                   @input="setInput(['user_name', $event])"
                   class="form-basket__input"
        />
        <BaseInput type="tel"
                   :value="inputs.phone.value"
                   :error="inputs.phone.error"
                   @input="setInput(['phone', $event])"
                   :mask="{mask: '+{38} ({\\0}#0)-000-00-00',
                      definitions: {'#': /[1-9]/},
                      unmask: true
                    }"
                   :label="$t('Телефон')"
                   class="form-basket__input"
        />
        <BaseInput :value="inputs.email.value"
                   :error="inputs.email.error"
                   :label="$t('Email')"
                   @input="setInput(['email', $event])"
                   class="form-basket__input"
        />
      </div>
      <div class="form-basket__label">{{ $t('Спосіб доставки') }}</div>
      <div class="form-basket__delivery-method-radio-list">
        <BaseRadioList :value="inputs.delivery_type.value"
                       :error="inputs.delivery_type.error"
                       @input="setInput(['delivery_type', $event])"
                       :options="deliveryMethods"
                       :getOptionLabel="getDeliveryOptionLabel"
                       class="form-basket__delivery-method-radio"
        />
      </div>

      <transition name="fade" mode="out-in">
        <div v-if="showByDelivery([DELIVERY_METHOD_ADDRESS])"
             key="address"
             class="form-basket__places"
        >
          <BaseSelect key="city"
                      :label="$t('Місто / населений пункт')"
                      :options="citiesList"
                      text-key="name"
                      :value="inputs.city_id.value"
                      :error="inputs.city_id.error"
                      @input="setInput(['city_id', $event])"
                      class="form-basket__select"
          />
          <BaseInput key="street"
                     :label="$t('Вулиця')"
                     :value="inputs.street.value"
                     @input="setInput(['street', $event])"
                     :error="inputs.street.error"
                     class="form-basket__input-group"
          />
          <BaseInput key="house_number"
                     :label="$t('№ будинку')"
                     :value="inputs.house_number.value"
                     @input="setInput(['house_number', $event])"
                     :error="inputs.house_number.error"
                     class="form-basket__input-group"
          />
          <BaseInput key="apartment_number"
                     :label="$t('№ квартири')"
                     :value="inputs.apartment_number.value"
                     @input="setInput(['apartment_number', $event])"
                     :error="inputs.apartment_number.error"
                     class="form-basket__input-group"
          />
          <BaseInput key="entrance"
                     :label="$t(`Під'їзд`)"
                     :value="inputs.entrance.value"
                     @input="setInput(['entrance', $event])"
                     :error="inputs.entrance.error"
                     class="form-basket__input-group"
          />
          <BaseInput key="intercom_code"
                     :label="$t('Код домофону')"
                     :value="inputs.intercom_code.value"
                     @input="setInput(['intercom_code', $event])"
                     :error="inputs.intercom_code.error"
                     class="form-basket__input-group"
          />
          <div class="form-basket__input-group">
            <div class="form-basket__input-group-checkbox">
              <BaseCheckbox :label="`⚡️${$t('Блискавично')}⚡️`"
                            :checked="inputs.quickly.value"
                            :disabled="!isQuicklyAllowed"
                            class="_accent"
                            @input="setQuicklyInput"
              />
              <InputError :value="inputs.quickly.error || (!isQuicklyAllowed ? $t('доступно тільки в робочий час') : null)"/>
            </div>
          </div>
          <DatePickerInput key="time"
                           class="form-basket__input-group"
                           :value="inputs.time.value"
                           :label="$t('Час доставки')"
                           v-bind="dateOptions"
                           @input="setTimeInput"
                           :error="inputs.time.error"
                           @change="handleChangeTime"
                           :open.sync="openTime"
          />
          <BaseInput key="comment"
                     :label="$t('Побажання до замовлення')"
                     textarea
                     :value="inputs.comment.value"
                     :error="inputs.comment.error"
                     @input="setInput(['comment', $event])"
                     row="4"
                     class="form-basket__input _full-width"
          />
        </div>
        <div v-else-if="showByDelivery([DELIVERY_METHOD_SELF])"
             key="self"
             class="form-basket__places"
        >
          <BaseSelect key="restaurant"
                      :label="$t('Оберіть заклад')"
                      :options="restaurantsList"
                      :disableOptions="restaurantsListDisabled"
                      text-key="name"
                      :value="inputs.pizzeria_id.value"
                      :error="inputs.pizzeria_id.error"
                      @input="setInput(['pizzeria_id', $event])"
                      class="form-basket__input _full-width"

          />
          <div class="form-basket__input-group">
            <div class="form-basket__input-group-checkbox">
              <BaseCheckbox :label="$t('Якнайшвидше')"
                            :checked="inputs.quickly.value"
                            :disabled="!isQuicklyAllowed"
                            class="_accent"
                            @input="setQuicklyInput"
              />
              <InputError :value="inputs.quickly.error || (!isQuicklyAllowed ? $t('доступно тільки в робочий час') : null)"/>
            </div>
          </div>
          <DatePickerInput key="time-self"
                           class="form-basket__input-group"
                           :value="inputs.time_self.value"
                           :label="$t('Час приготування')"
                           v-bind="dateOptions"
                           @input="setTimeSelfInput"
                           :open.sync="openTime"
                           :error="inputs.time.error || inputs.time_self.error"
                           @change="handleChangeTime"
          />
          <BaseInput key="comment"
                     :label="$t('Побажання до замовлення')"
                     textarea
                     :value="inputs.comment.value"
                     :error="inputs.comment.error"
                     @input="setInput(['comment', $event])"
                     row="4"
                     class="form-basket__input _full-width"
          />
        </div>
      </transition>

      <div class="form-basket__label">{{ $t('Cпосіб оплати') }}</div>
      <div class="form-basket__versions">
        <BaseRadioList :value="inputs.payment_type.value"
                       :error="inputs.payment_type.error"
                       @input="setPaymentMethod"
                       :options="paymentMethods"
                       class="form-basket__radio-group"
        />
      </div>

      <div class="form-basket__label">{{ $t('Кількість приборів') }}</div>
      <div class="form-basket__devices-count">
        <div class="form-basket__devices-count-item">
          <BaseSvg name="ico-cutlery"/>
          <div class="form-basket__devices-count-item-name">
            {{ $t('Прибори') }}
          </div>
          <Quantity :value="inputs.devices_count.value"
                    :error="inputs.devices_count.error"
                    :min="0"
                    @input="setInput(['devices_count', $event])"
                    class="form-basket__devices-count-item-quantity _small"
          />
          <BaseInputError :value="inputs.devices_count.error"/>
        </div>
        <template v-if="withSticks">
          <div class="form-basket__devices-count-item">
            <BaseSvg name="ico-sticks"/>
            <div class="form-basket__devices-count-item-name">
              {{ $t('Палички') }}
            </div>
            <Quantity :value="inputs.sticks_count.value"
                      :error="inputs.sticks_count.error"
                      :min="0"
                      @input="setInput(['sticks_count', $event])"
                      class="form-basket__devices-count-item-quantity _small"
            />
            <BaseInputError :value="inputs.sticks_count.error"/>
          </div>
          <div class="form-basket__devices-count-item">
            <BaseSvg name="ico-sticks"/>
            <div class="form-basket__devices-count-item-name">
              {{ $t('Навчальні палички') }}
            </div>
            <Quantity :value="inputs.student_sticks_count.value"
                      :error="inputs.student_sticks_count.error"
                      :min="0"
                      @input="setInput(['student_sticks_count', $event])"
                      class="form-basket__devices-count-item-quantity _small"
            />
            <BaseInputError :value="inputs.student_sticks_count.error"/>
          </div>
        </template>
      </div>

    </form>
  </div>
</template>

<script>
  import formMixins from '@/mixins/formMixins'
  import {mapState} from 'vuex'
  import {DELIVERY_METHOD_ADDRESS, DELIVERY_METHOD_SELF} from '@/constants/deliveryMethods'
  import {PAYMENT_METHOD_ONLINE} from '@/constants/paymentMethods'
  import DatePickerInput from '@/components/DatePickerInput'
  import Quantity from '@/components/Quantity'
  import BaseInputError from './BaseInputError'
  import moment from 'moment'
  import InputError from '@/components/InputError'


  export default {
    mixins: [formMixins],
    name: 'FormBasket',
    props: {
      inputs: {
        type: Object,
        required: true
      },
      restaurantsSelfPickup: null
    },
    components: {
      InputError,
      BaseInputError,
      DatePickerInput,
      Quantity
    },
    data() {
      return {
        DELIVERY_METHOD_ADDRESS,
        DELIVERY_METHOD_SELF,
        openTime: false,
        citiesList: [],
        restaurantsList: []
      }
    },
    computed: {
      ...mapState('settings', ['settings']),
      ...mapState('basket', ['basket']),
      ...mapState({
        selfPickingActive(state) {
          return JSON.parse(JSON.stringify(this.$get(state.settings.settings.orders, 'selfPickingSite', false)))
        },
        deliveryMethods(state) {
          return state.deliveryMethods.reduce((result, item) => {
            if (item.id === DELIVERY_METHOD_SELF && !this.selfPickingActive) {
              return result
            }
            item = {...item}
            item.name = this.$t(item.name)
            result.push(item)
            return result
          }, [])
        },
        paymentMethods(state) {
          return state.paymentMethods.reduce((result, item) => {
            if (item.id === PAYMENT_METHOD_ONLINE && this.hasWeightProducts) {
              return result
            }

            item = {...item}
            item.name = this.$t(item.name)
            result.push(item)

            return result
          }, [])
        }
      }),
      getMinTimeForPreparing() {
        return this.showByDelivery([DELIVERY_METHOD_ADDRESS]) ?
            parseFloat(this.$get(this.settings.orders, 'min_time_for_preparing', 0)) :
            parseFloat(this.$get(this.settings.orders, 'min_time_for_preparing_self', 0))
      },
      getMinDateTime() {
        let now = new Date()
        let minTimeDelta = this.getMinTimeForPreparing || 0
        now.setMinutes(now.getMinutes() + minTimeDelta)
        return now
      },
      timeOptions() {
        let schedule = {}
        let addressSchedule = this.$get(this.settings.orders, 'address_delivery_time', null)
        let selfPickSchedule = this.$get(this.settings.orders, 'time_for_pickup', null)

        if (this.showByDelivery([DELIVERY_METHOD_ADDRESS])) {
          schedule.start = addressSchedule && addressSchedule.start || '00:00'
          schedule.end = addressSchedule && addressSchedule.end || '00:00'
        }
        if (this.showByDelivery([DELIVERY_METHOD_SELF])) {
          schedule.start = selfPickSchedule && selfPickSchedule.start || '00:00'
          schedule.end = selfPickSchedule && selfPickSchedule.end || '00:00'
        }

        let newDate = new Date(`0000-01-01T${schedule.start}:00`)
        newDate.setMinutes(newDate.getMinutes() + this.getMinTimeForPreparing || 0)
        let minTime = `${newDate.getHours().toString().padStart(2, '0')}:${newDate.getMinutes().toString().padStart(2, '0')}`

        let res = {
          start: minTime,
          end: schedule.end,
          step: '00:05',
          format: 'HH:mm'
        }

        return res
      },
      isQuicklyAllowed() {
        const addressSchedule = this.$get(this.settings.orders, 'address_delivery_time', {})
        const offset = Number(this.$get(this.settings, 'giftedproducts.zone_2_time', 0))
        const start = moment(`${moment().format('YYYY-MM-DD')}T${addressSchedule.start || '00:00'}:00`)
        const end = moment(`${moment().format('YYYY-MM-DD')}T${addressSchedule.end || '00:00'}:00`)
        end.subtract(offset, 'minutes')
        return moment().isBetween(start, end)
      },
      dateOptions() {
        return {
          type: 'datetime',
          format: 'HH:mm',
          editable: false,
          defaultValue: this.getMinDateTime,
          disabledDate: this.disabledDate,
          disabledTime: this.disabledDateTime,
          timePickerOptions: this.timeOptions,
          showTimeHeader: true,
          timeTitleFormat: 'DD.MM.YYYY'
        }
      },
      hasWeightProducts() {
        return this.basket.some(item => {
          return item.product.weight_dish
        })
      },
      withSticks() {
        return this.basket.some(item => {
          return item.product.category.with_sticks
        })
      },
      restaurantsListDisabled() {
        let list = this.restaurantsList

        this.basket.forEach(basketItem => {
          let categoryRestaurants = this.$get(basketItem, 'product.category.pickup_restaurants', [])
          if (!categoryRestaurants || !Array.isArray(categoryRestaurants)) {
            list = []
          }

          list = list.filter(rest => {
            return categoryRestaurants.some(item => item.id === rest.id)
          })
        })

        return list
      }
    },
    methods: {
      showByDelivery(types) {
        const id = this.$get(this.inputs.delivery_type.value, 'id')
        return id && types.includes(id)
      },
      setInput(value) {
        this.$emit('input', value)
      },
      setPaymentMethod(value) {
        if (value.id === PAYMENT_METHOD_ONLINE && this.hasWeightProducts) {
          this.$vModal.open('message', {
            title: this.$t('Оплата онлайн не можлива для вагових страв'),
            message: this.$t('Виберіть інший тип оплати')
          })

          return
        }

        this.setInput(['payment_type', value])
      },
      disabledDate(date) {
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        const availableDays = 1
        return date < today || date > new Date(today.getTime() + availableDays * 24 * 3600 * 1000)
      },
      disabledDateTime(date) {
        return date < this.getMinDateTime
      },
      handleChangeTime(value, type) {
        if (type === 'minute') {
          this.openTime = false
        }
      },
      getDeliveryOptionLabel(option) {
        const isSelfPickingDiscountActive = JSON.parse(JSON.stringify(this.$get(this.settings.orders, 'isSelfPickingDiscountActive', false)))
        const selfPickingDiscount = +this.$get(this.settings.orders, 'selfPickingDiscount', 0)
        if (isSelfPickingDiscountActive && selfPickingDiscount > 0 && option.id === DELIVERY_METHOD_SELF) {
          return `${option.name} <b>(-${selfPickingDiscount}%)</b>`
        }
        return option.name
      },
      setQuicklyInput(val) {
        this.setInput(['quickly', val])
        if (val) {
          this.setInput(['time', null])
          this.setInput(['time_self', null])
        }
      },
      setTimeInput(val) {
        this.setInput(['time', val])
        if (val) {
          this.setInput(['quickly', false])
        }
      },
      setTimeSelfInput(val) {
        this.setInput(['time_self', val])
        if (val) {
          this.setInput(['quickly', false])
        }
      }
    },
    created() {
      this.$store.dispatch('citiesList/load').then((data) => {
        this.citiesList = data
        let defaultCity = data.find(item => item.is_default_city)
        if (defaultCity) {
          this.setInput(['city_id', defaultCity])
        } else {
          this.setInput(['city_id', data[0]])
        }
      })
      this.$store.dispatch('restaurant/load').then((data) => {
        this.restaurantsList = data
      })
      this.setInput(['delivery_type', this.deliveryMethods[0]])
      this.setInput(['payment_type', this.paymentMethods[0]])
    },
    watch: {
      withSticks(value) {
        if (!value) {
          this.setInput(['sticks_count', 0])
          this.setInput(['student_sticks_count', 0])
        }
      },
      isQuicklyAllowed: {
        immediate: true,
        handler(to) {
          if (!to) {
            this.setInput(['quickly', false])
          }
        }
      }
    }
  }
</script>
